export const ipfsImage =
  'https://cryptorus.mypinata.cloud/ipfs/QmP6UryAQUYmcFosrVPDYEsW2QCgiXdTqoByUuBCiNkUyJ/cryptorus';

export const v2IpfsImage =
  'https://cryptorus.mypinata.cloud/ipfs/QmSoQzK7VBaZh2Zu9dAMLYAPtzyJWRuNAsXoFzDen1mA9Y/cryptorus-baby';

export const kwangBoostImage =
  'https://cryptorus.mypinata.cloud/ipfs/QmaQ7nU4kGtxfap87MWzFzfbKGG9QGQZfoSviWvSn8frvJ/';

export const SEIGE_START_AT = '2024-01-26T22:00:00';

export const SEIGE_EXPIRE_AT = '2024-02-02T23:59:59';

export const SEIGE_NUMBER = 27;

export const SEIGE_TITLE = '티라노의 오만';

export const SEIGE_MULTIPLY = 1;

export const ITEM_IMAGE =
  'https://cryptorus.mypinata.cloud/ipfs/QmTP3CW2YWsYstcfu2bm1mtSiYBAtRTDDXZh1nGi9h57HK';

